import {request} from './request'
import baseUrlObj from "./baseURL"
const systemNumber = baseUrlObj.systemNumber

// 获取证书信息 /ewi/guest/Cert/QueryUserCertList
export function queryUserCertList(formData){
  return request({
    url: '/ewi/guest/Cert/QueryUserCertList',
    method: 'post',
    data: {
      authCode: formData.authCode,
      certNumber: formData.certNumber,
      certType: formData.certType,
      fullName: formData.fullName,
      idCard: formData.idCard,
      uuid: formData.uuid,
      systemNumber: systemNumber,
    }
  })
}
